<template>
  <div class="pb-32 pt-6">

    <h2 class="text-2xl font-bold mb-10">
      Standard Loan Disbursement Statistics
    </h2>

    <statistics :stats="stats" />
  </div>
</template>

<script>
export default {
  name: 'DisbursementStatistics',
  data() {
    return {
      stats: this.$options.resource([]),
    }
  },
  beforeMount() {
    this.getStats();
  },
  methods: {
    async getStats() {
      this.stats.error = false;
      this.stats.loading = true;

      await this.sendRequest('admin.loans.personal.statistics', {
        success: response => {
          this.stats.data = response.data.statistics;
        },
        error: error => {
          this.stats.error = error;
        }
      });

      this.stats.loading = false;
    }
  }
}
</script>